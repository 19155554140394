import React from 'react';
import { MDBFooter, MDBContainer, MDBIcon, MDBCol, MDBRow, MDBTypography } from 'mdb-react-ui-kit';

export default function App() {
  return (
    <MDBFooter className="text-center" color="black" bgColor=".bg-transparent">
      <MDBContainer className="p-4">
        <section className="">
          <MDBRow>
            <MDBCol lg="3" md="6" className="mb-4 mb-md-0 footer_left">
              <img
                src="/heywaitr.b08c1632.jpg"
                className="footer_image"
                alt="..."
                style={{ maxWidth: '10em' }}
              />
            </MDBCol>

            <MDBCol lg="3" md="6" className="mb-4 mb-md-0 footer_left">
              <MDBTypography tag="strong">+45 78 70 70 25</MDBTypography>
              <br />
              <MDBTypography tag="strong">hey@heywaitr.com</MDBTypography>
              <br />
              <MDBTypography tag="small">heywaitr ApS</MDBTypography>
              <br />
              <MDBTypography tag="small">Fruebjergvej 3</MDBTypography>
              <br />
              <MDBTypography tag="small">1620 København Ø</MDBTypography>
            </MDBCol>

            <MDBCol lg="3" md="6" className="mb-4 mb-md-0 footer_left">
              <MDBTypography tag="strong">
                <a href="https://heywaitr.com/">Home</a>
              </MDBTypography>
              <br />
              <MDBTypography tag="strong">
                <a href="https://heywaitr.com/#aboutus">About us</a>
              </MDBTypography>
              <br />
              <MDBTypography tag="strong">
                <a href="https://heywaitr.com/#footer">Contact</a>
              </MDBTypography>
              <br />
              <MDBTypography tag="strong">
                <a href="https://heywaitr.com/heywaitr-app-global-terms-and-conditions/">
                  Terms and conditions
                </a>
              </MDBTypography>
              <br />
              <MDBTypography tag="small">Danmark<br />CVR: 42068497</MDBTypography>
              <br />
              <MDBTypography tag="small">Norge<br />Org.nr.: 934990846</MDBTypography>
            </MDBCol>

            <MDBCol lg="3" md="6" className="mb-4 mb-md-0" style={{ zIndex: '-1' }}>
              <MDBTypography tag="strong">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ border: 'none' }}
                  className="btn btn-outline-dark btn-floating m-1"
                  href="https://www.facebook.com/HeyWaitr"
                  role="button"
                >
                  <MDBIcon size="2x" fab icon="facebook-square" />
                </a>
                {/* <a target="_blank" rel="noopener noreferrer" style={{border:"none"}} className='btn btn-outline-dark btn-floating m-1' href='https://twitter.com/HeyWaitr' role='button'>
                <MDBIcon size='2x'  fab icon="twitter" />
                </a>   */}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ border: 'none' }}
                  className="btn btn-outline-dark btn-floating m-1"
                  href="https://www.instagram.com/heywaitr/"
                  role="button"
                >
                  <MDBIcon size="2x" fab icon="instagram" />
                </a>
              </MDBTypography>
            </MDBCol>
          </MDBRow>
        </section>
      </MDBContainer>

      <div className="text-center p-3" style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
        © 2025 Copyright&nbsp;-&nbsp;
        <a className="text-black" href="/">
          heywaitr.com
        </a>
      </div>
    </MDBFooter>
  );
}
