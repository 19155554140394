import React, { useEffect, useState, createContext, useContext } from 'react';
import {
  MDBNavbar,
  MDBCheckbox,
  MDBContainer,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBNavbarToggler,
  MDBNavbarBrand,
  MDBCollapse,
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardHeader,
  MDBTabs,
  MDBTabsItem,
  MDBValidationItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
  MDBRadio,
  MDBBtnGroup,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBTypography,
  MDBModalBody,
  MDBModalFooter,
  MDBInput,
  MDBInputGroup,
  MDBCardImage,
} from 'mdb-react-ui-kit';
import axios from 'axios';
import configData from '../config.json';
import { fetchToken, onMessageListener, messaging, isPushSupported } from '../utils/firebase';

export const NofificationContext = createContext();

export function NotificationWrapper({ children }) {
  const broadcast = new BroadcastChannel('notif-channel');

  const [showNotificationInfoModal, setShowNotificationInfoModal] = useState(false);
  const [notificationModal, setNotificatioModal] = useState({
    open: false,
    notification: {},
  });
  const [closePendingModal, setClosePendingModal] = useState(false);
  const [notSupportedPushModal, setNotSupportedPushModal] = useState(false);

  // callback function to call when event triggers
  const onPageLoad = async () => {
    //check if push is supported
    const isSupported = await isPushSupported();
    if (!isSupported) {
      // const hasAcceptedNotSupported = localStorage.getItem('hw-accept-not-supported-push');
      // if (!hasAcceptedNotSupported) {
      //   setNotSupportedPushModal(true);
      // }
      return;
    }

    const hasAcceptedWarning = localStorage.getItem('hw-accept-notif-warning');
    if (hasAcceptedWarning) {
      getFCMToken();
    } else {
      setShowNotificationInfoModal(true);
    }
  };

  useEffect(() => {
    // Check if the page has already loaded
    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad, false);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);

  const getFCMToken = async () => {
    try {
      const token = await fetchToken();
      //   console.log('USER TOKEN', token);

      let userProfile = localStorage.getItem('user_profile');
      userProfile = JSON.parse(userProfile);

      await axios({
        method: 'POST',
        url: `${configData.SERVER_URL}/user/web/registrationtoken/store`,
        data: {
          registrationtoken: token,
        },
        headers: {
          Authorization: `Bearer ${userProfile?.token || ''}`,
        },
      });
    } catch (ex) {
      console.log('getFCMToken() ', ex.message);
    }
  };

  const dismissModal = () => {
    setShowNotificationInfoModal(false);
    localStorage.setItem('hw-accept-notif-warning', true);
    getFCMToken();
  };

  // Listen to the push (BACKGROUND)
  broadcast.onmessage = (event) => {
    console.log('BACKGROUND ', event.data.payload);
    setNotificatioModal({
      open: true,
      notification: event.data.payload,
    });
    setClosePendingModal(true);
  };

  // Listen to the push (FOREGROUND)
  onMessageListener()
    .then((payload) => {
      console.log('FOREGROUND ', payload);
      setNotificatioModal({
        open: true,
        notification: payload,
      });
      setClosePendingModal(true);
    })
    .catch((err) => console.log('failed: ', err));

  return (
    <NofificationContext.Provider
      value={{
        onPageLoad,
        closePendingModal,
        setClosePendingModal,
        setNotificatioModal,
        notificationModal,
      }}
    >
      {children}
      {/* Enable push notif warning */}
      <MDBModal
        show={showNotificationInfoModal}
        setShow={setShowNotificationInfoModal}
        staticBackdrop
      >
        <MDBModalDialog className="detail_modal">
          <MDBModalContent
            style={{
              marginTop: '18vh',
              padding: '4rem 1rem',
              maxWidth: '600px',
            }}
          >
            <MDBModalBody className="text-center">
              <h4>
              Please accept push notifications. We use push notifications to send you updates about your order, such as whether it has been accepted, is ready for pickup, and much more.
              </h4>
              <MDBBtn
                color="success p-1 my-4"
                className="add_to_cart"
                style={{
                  width: '150px',
                  height: '40px',
                }}
                onClick={dismissModal}
              >
                Jeg har forstået
              </MDBBtn>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      {/* Push not supported */}
      <MDBModal show={notSupportedPushModal} setShow={setNotSupportedPushModal} staticBackdrop>
        <MDBModalDialog className="detail_modal">
          <MDBModalContent
            style={{
              marginTop: '18vh',
              padding: '4rem 1rem',
              maxWidth: '600px',
            }}
          >
            <MDBModalBody className="text-center">
              <h4>
                Vi kan se du bruger Safari som browser. Safari browseren understøtter desværre ikke
                al vores funktionalitet. For den bedste oplevelse, så brug Chrome, Firefox eller
                Edge.
              </h4>
              <MDBBtn
                color="success p-1 my-4"
                className="add_to_cart"
                style={{
                  width: '150px',
                  height: '40px',
                }}
                onClick={() => {
                  setNotSupportedPushModal(false);
                  localStorage.setItem('hw-accept-not-supported-push', true);
                }}
              >
                Jeg har forstået
              </MDBBtn>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      {/* Notification modal handler */}
      <MDBModal show={notificationModal.open} staticBackdrop>
        <MDBModalDialog className="detail_modal modal-center-content">
          <MDBModalContent style={{ maxWidth: '600px' }}>
            <MDBModalBody
              className="text-center"
              style={{
                marginTop: '7vh',
                padding: '4rem 1rem',
              }}
            >
              <h4>{notificationModal?.notification?.notification?.body}</h4>
              <h5>{notificationModal?.notification?.data?.comment}</h5>
              <MDBBtn
                color="success p-1 my-4"
                className="add_to_cart"
                style={{
                  width: '150px',
                  height: '40px',
                }}
                onClick={() => setNotificatioModal({ open: false, notification: {} })}
              >
                Jeg har forstået
              </MDBBtn>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </NofificationContext.Provider>
  );
}

export function useNotificationContext() {
  return useContext(NofificationContext);
}
