import React, { Component, useState, useEffect, useLayoutEffect } from 'react';
import { StarRatings } from './index';
import { Email, Item, Span, A, renderEmail } from 'react-html-email';

function Survey(props) {
  useLayoutEffect(() => {
    try {
      document.body.style.backgroundColor = '#EDEDED';
    } catch {}
  }, []);

  return (
    <div className="container text-center">
      <div className="head">
        <h5>Hordan var din oplevelse hos</h5>
        <h3>Chowking</h3>
      </div>

      <div className="subhead">
        <p>
          Bedom din oplevelse ved at valge antallet af stjerner, hvor 5 sterner er den hojeste
          bedommelse.
        </p>

        <div className="startcont my-4">
          <div>
            <div className={`starbox`}>
              <span className={'ratenum star_1'}>1</span>
              <i className="far fa-star" />
            </div>
            <div className="ratetxt" style={{ color: '#fff' }}>
              Darlig
            </div>
          </div>
          <div>
            <div className={`starbox`}>
              <span className={'ratenum star_2'}>2</span>
              <i className="far fa-star" />
            </div>
            <div className="ratetxt" style={{ color: '#fff' }}>
              Middeladig
            </div>
          </div>
          <div>
            <div className={`starbox`}>
              <span className={'ratenum star_3'}>3</span>
              <i className="far fa-star" />
            </div>
            <div className="ratetxt" style={{ color: '#fff' }}>
              God
            </div>
          </div>
          <div>
            <div className={`starbox`}>
              <span className={'ratenum star_4'}>4</span>
              <i className="far fa-star" />
            </div>
            <div className="ratetxt" style={{ color: '#fff' }}>
              Meget god
            </div>
          </div>
          <div>
            <div className={`starbox`}>
              <span className={'ratenum star_5'}>5</span>
              <i className="far fa-star" />
            </div>
            <div className="ratetxt" style={{ color: '#fff' }}>
              Fremragende
            </div>
          </div>
        </div>

        {/* <StarRatings
          category={mainRating}
          setCategories={setMainRating}
          textColor="#fff"
          userData={userData}
          setUserData={setUserData}
        /> */}
      </div>

      <div className="footer">
        <p>Kare Michael,</p>

        <p>
          Vi haber, at du har haft en positiv oplevelse hos Chowking. Din mening er utrolig vigtig,
          og vi vil meget gerne hore om dine indtryk og oplevelser.
        </p>

        <p>
          Ved at dele dine tanker og feedback, hjalper du Chowking med at forbedre og sikre, at de
          fortsat kan levere en service, der moder dine forventninger.
        </p>
      </div>

      <div className="footer">
        <img src="https://heywaitr-staging.herokuapp.com/static/media/heywaitr.b08c1632.jpg" />

        <p className="mt-4">
          <b>Order Details</b> - Order ID: 9432-423423-5334 - March 7th 2024
        </p>
      </div>
    </div>
  );
}

const emailHTML = (
  <Email title="Hello World!">
    <Item align="center">
      <Span fontSize={20}>
        This is an example email made with:
        <A href="https://github.com/chromakode/react-html-email">react-html-email</A>.
      </Span>
    </Item>
  </Email>
);

// export default Survey;

export default Survey;
