import React, { useState, useEffect, useRef } from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { useGoogleLogin, googleLogout } from '@react-oauth/google';
import {
  MDBNavbar,
  MDBCheckbox,
  MDBContainer,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBNavbarToggler,
  MDBNavbarBrand,
  MDBCollapse,
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardHeader,
  MDBTabs,
  MDBTabsItem,
  MDBValidationItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
  MDBRadio,
  MDBBtnGroup,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBTypography,
  MDBModalBody,
  MDBModalFooter,
  MDBInput,
  MDBInputGroup,
  MDBCardImage,
} from 'mdb-react-ui-kit';
import axios from 'axios';
import configData from '../config.json';
import { ToastContainer, toast } from 'react-toastify';
import { deleteUserToken } from '../utils/firebase';
import { useNotificationContext } from '../context/notification';
import { useOrderContext } from '../context/order';
import { useNavigate, Link } from 'react-router-dom';

export default function NavBar(props) {
  const navigate = useNavigate();

  const notificationContext = useNotificationContext();
  const orderContext = useOrderContext();

  const [toggleCreateAccountModal, setToggleCreateAccountModal] = useState(false);
  const [userProfile, setUserProfile] = useState([]);
  const [basicModal2, setBasicModal2] = useState(false);
  const [basicModal3, setBasicModal3] = useState(false);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [contact, setContactSignUp] = useState('');
  const [agree_to_terms, setAgreeTerms] = useState('');

  const [signupData, setSignupData] = useState({});

  const [forgotPassword, setForgotPassword] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');

  useEffect(() => {
    if (
      typeof localStorage.getItem('user_profile') !== 'undefined' &&
      localStorage.getItem('user_profile') !== null
    ) {
      checkreepayregistration();
      setUserProfile(JSON.parse(localStorage.getItem('user_profile')));
    }
  }, []);

  useEffect(() => {
    if (orderContext.signupModal) {
      setBasicModal3(true);
    } else {
      setBasicModal3(false);
    }
  }, [orderContext.signupModal]);

  useEffect(() => {
    if (!basicModal3) {
      orderContext.setSignupModal(false);
    }
  }, [basicModal3]);

  useEffect(() => {
    if (props.setUserProfile) {
      props.setUserProfile(userProfile);
    }
  }, [userProfile]);

  const checkreepayregistration = async () => {
    try {
      let userProfile = JSON.parse(localStorage.getItem('user_profile'));

      const request = await axios({
        method: 'GET',
        url: `${configData.SERVER_URL}/user/checkreepayregistration`,
        headers: {
          Authorization: `Bearer ${userProfile?.token || ''}`,
        },
      });
    } catch (ex) {
      console.log('checkreepayregistration() => ', ex.message);
    }
  };

  const logOut = async (response) => {
    localStorage.removeItem('user_profile');
    localStorage.removeItem('orders');
    localStorage.removeItem('hw_pending_orderv2');
    localStorage.removeItem('guest-uui');

    orderContext.setIsGuest(false);
    orderContext.setGuestId('');

    setUserProfile([]);
    googleLogout();
    deleteUserToken();
    navigate('/');
  };

  const toggleShow2 = () => {
    if (userProfile !== undefined && userProfile !== null && userProfile.profile) {
      const my_orders = JSON.parse(localStorage.getItem('orders'));
      const orderInfo = JSON.parse(localStorage.getItem('hw_preorder_info'));

      console.log('my_orders', my_orders);

      axios({
        method: 'GET',
        url: `${configData.SERVER_URL}/orders/v2/pmlists?restaurant=${orderInfo?.restaurant_id}`,
        data: null,
        headers: {
          Authorization: `Bearer ${userProfile.token}`,
        },
      }).then((response) => {
        props?.setCards?.(response.data);
      });
      props?.setCheckOutItems?.(my_orders);
      setBasicModal2(!basicModal2);
    } else {
      setBasicModal3(!basicModal3);
    }
  };

  const usernameChange = (value) => {
    setUsername(value);
  };

  const passwordChange = (value) => {
    setPassword(value);
  };

  const emailChange = (value) => {
    setEmail(value);
  };

  const contactChangeSignUp = (value) => {
    setContactSignUp(value);
  };

  const agreeTermsChange = (value) => {
    setAgreeTerms(value);
  };

  const signUp = () => {
    if (agree_to_terms) {
      if (email == '' || username == '' || password == '') {
        checkRequiredFields();
      } else {
        const data = `email=${email}&name=${username}&password=${password}&mobilenumber=${contact}`;
        const config = {
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
        };
        axios
          .post(`${configData.SERVER_URL}/user/register`, data, { config })
          .then((response) => {
            if (response.data.Message == 'Konto er hermed oprettet!') {
              setUserProfile(response.data);
              localStorage.setItem('user_profile', JSON.stringify(response.data));
              // console.log(response.data);
              setToggleCreateAccountModal(false);
              notificationContext.onPageLoad();
            } else {
              notifyError();
            }
          })
          .catch((error) => {
            // error.response.data
            emailExisting();
          });
      }
    } else {
      agreeToTermsFirst();
    }
  };

  const agreeToTermsFirst = () => {
    toast('Please check the box to accept the terms before proceeding.', {
      containerId: 'B',
      icon: <MDBIcon fas icon="exclamation-triangle" />,
    });
  };

  const notifyError = () => {
    toast('Your login credentials are incorrect.', {
      containerId: 'A',
      icon: <MDBIcon fas icon="exclamation-triangle" />,
    });
  };

  const checkRequiredFields = () => {
    toast('Please fill in the required fields', {
      icon: <MDBIcon fas icon="exclamation-triangle" />,
    });
  };

  const emailExisting = () => {
    toast('Email already in use.', {
      containerId: 'B',
      icon: <MDBIcon fas icon="exclamation-triangle" />,
    });
  };

  const logIn = () => {
    const data = `email=${username}&password=${password}`;
    const config = {
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
    };
    axios
      .post(`${configData.SERVER_URL}/user/login`, data, { config })
      .then((response) => {
        if (response.data.Message == 'User successfully logged in') {
          setUserProfile(response.data);
          localStorage.setItem('user_profile', JSON.stringify(response.data));
          closeModal3();
          notificationContext.onPageLoad();
          refetchResto();
        } else {
          setUserProfile([]);
          notifyError();
        }
      })
      .catch((error) => {
        // element.parentElement.innerHTML = `Error: ${error.message}`;
        console.error('There was an error!', error);
        setUserProfile([]);
        notifyError();
      });
  };

  const closeModal3 = () => {
    setBasicModal3(!basicModal3);
  };

  const responseFacebook = (response) => {
    localStorage.setItem('return_url', window.location.href);
    const data = `accesstoken=${response.accessToken}&facebookid=${response.id}`;
    const config = {
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
    };
    axios
      .post(`${configData.SERVER_URL}/user/auth/facebook/validate`, data, {
        config,
      })
      .then((response2) => {
        setUserProfile(response2.data);
        localStorage.setItem('user_profile', JSON.stringify(response2.data));
        notificationContext.onPageLoad();
        refetchResto();
      })
      .catch((error) => {
        console.error('There was an error in calling api!', error);
      });
    closeModal3();
  };

  const googleSingIn = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      localStorage.setItem('return_url', window.location.href);
      const data = `tokenid=${tokenResponse.access_token}`;
      const config = {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
      };
      axios
        .post(`${configData.SERVER_URL}/user/auth/google/validate/web`, data, {
          config,
        })
        .then((response2) => {
          setUserProfile(response2.data);
          localStorage.setItem('user_profile', JSON.stringify(response2.data));
          notificationContext.onPageLoad();
          refetchResto();
        })
        .catch((error) => {
          console.error('There was an error in calling api!', error);
        });
      closeModal3();
    },
  });

  const handleChangeSignup = (key, value) => {
    setSignupData({
      ...signupData,
      [key]: value,
    });
  };

  const isEmailNotMatch = () => {
    return signupData.email1 != signupData.email2;
  };

  const isPasswordNotMatch = () => {
    return signupData.password1 != signupData.password2;
  };

  const signUpNew = () => {
    if (agree_to_terms) {
      if (
        !signupData.firstname ||
        !signupData.lastname ||
        !signupData.email1 ||
        !signupData.email2 ||
        !signupData.password1 ||
        !signupData.password2
      ) {
        checkRequiredFields();
      } else if (
        signupData.email1 != signupData.email2 ||
        signupData.password1 != signupData.password2
      ) {
        toast('Kontroller venligst eventuelle fejl i felterne', {
          icon: <MDBIcon fas icon="exclamation-triangle" />,
        });
      } else if (!isEmailValid(signupData.email1) || !isEmailValid(signupData.email2)) {
        toast('Ugyldig emailadresse', {
          icon: <MDBIcon fas icon="exclamation-triangle" />,
        });
      } else {
        const data = {
          email: signupData.email2,
          name: signupData.firstname + ' ' + signupData.lastname,
          password: signupData.password2,
          mobilenumber: signupData.mobilenumber,
        };
        const config = {
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
        };
        axios
          .post(`${configData.SERVER_URL}/user/register`, data, { config })
          .then((response) => {
            if (response.data.Message == 'User successfully registered.') {
              toast('Account successfully created', {
                containerId: 'A',
                icon: <MDBIcon fas icon="check-circle" />,
                autoClose: false,
              });

              setToggleCreateAccountModal(false);
              // setBasicModal3(true);
              notificationContext.onPageLoad();

              logInUponSignup(signupData.email2, signupData.password2);

              return;
              // setUserProfile(response.data);
              // localStorage.setItem('user_profile', JSON.stringify(response.data));
              // // console.log(response.data);
              // setToggleCreateAccountModal(false);
              // notificationContext.onPageLoad();
            } else {
              notifyError();
            }
          })
          .catch((error) => {
            // error.response.data
            emailExisting();
          });
      }
    } else {
      agreeToTermsFirst();
    }
  };

  const isEmailValid = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re?.test(email);
  };

  const refetchResto = () => {
    if (props.getRestaurants) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          function (position) {
            // console.log("Latitude is :", position.coords.latitude);
            // console.log("Longitude is :", position.coords.longitude);
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            const payload = {
              ignoreDineinTakeout: true,
              geoLocation: {
                coordinates: [longitude, latitude],
              },
            };
            props.getRestaurants(payload);
          },
          () => {
            const payload = {
              ignoreDineinTakeout: true,
              geoLocation: {
                coordinates: [0, 0],
              },
            };
            props.getRestaurants(payload);
          },
        );
      } else {
        const payload = {
          ignoreDineinTakeout: true,
          geoLocation: {
            coordinates: [0, 0],
          },
        };
        props.getRestaurants(payload);
      }
    }
  };

  const logInUponSignup = (email, password) => {
    const data = `email=${email}&password=${password}`;
    const config = {
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
    };
    axios
      .post(`${configData.SERVER_URL}/user/login`, data, { config })
      .then((response) => {
        if (response.data.Message == 'User successfully logged in') {
          setUserProfile(response.data);
          localStorage.setItem('user_profile', JSON.stringify(response.data));

          notificationContext.onPageLoad();
          refetchResto();
        } else {
          setUserProfile([]);
          notifyError();
        }
      })
      .catch((error) => {
        // element.parentElement.innerHTML = `Error: ${error.message}`;
        console.error('There was an error!', error);
        setUserProfile([]);
        notifyError();
      });
  };

  const submitReset = (e) => {
    e.preventDefault();

    const data = {
      email: forgotPasswordEmail,
    };
    const config = {
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
    };
    axios
      .post(`${configData.SERVER_URL}/user/forgotpassword`, data, { config })
      .then((response) => {
        toast('Please check your email.', {
          containerId: 'A',
          autoClose: false,
        });

        setForgotPassword(false);
        setForgotPasswordEmail('');
      })
      .catch((error) => {
        toast('Email not found', {
          containerId: 'B',
          icon: <MDBIcon fas icon="exclamation-triangle" />,
        });
      });
  };

  const logInAsGuest = () => {
    const uuid = new Date().getTime();
    orderContext.setIsGuest(true);
    orderContext.setGuestId(uuid);
    localStorage.setItem('guest-uui', uuid);
    if (orderContext.shouldShowReview) {
      orderContext.setReviewModal(true);
    }
  };

  return (
    <>
      <MDBNavbar className="obfuscate_detail_navbar" style={{ background: '#fafafa' }} expand="lg">
        <MDBContainer className="header_container" fluid>
          <MDBNavbarBrand className="custom_navbar_item">
            <Link className="custom_navbar_links" to="/">
              <img src="/logo-icon.png" height="35" alt="" loading="lazy" />
            </Link>
          </MDBNavbarBrand>

          <MDBNavbarNav className="customNavbarNav">
            <MDBNavbarItem className="custom_navbar_item cursor-pointer" id="_Forside">
              <MDBNavbarLink
                className="custom_navbar_links_text "
                onClick={() => {
                  navigate('/');
                }}
              >
                Home
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem className="custom_navbar_item cursor-pointer" id="_Restauranter">
              <MDBNavbarLink
                className="custom_navbar_links_text "
                onClick={() => {
                  navigate('/');
                }}
              >
                Restaurants
              </MDBNavbarLink>
            </MDBNavbarItem>
            {localStorage.getItem('user_profile') !== undefined &&
            localStorage.getItem('user_profile') !== null ? (
              <MDBNavbarItem className="custom_navbar_item cursor-pointer">
                <MDBNavbarLink
                  className="custom_navbar_links_text "
                  onClick={() => {
                    navigate('/orders');
                  }}
                >
                  Ordre
                </MDBNavbarLink>
              </MDBNavbarItem>
            ) : null}

            <MDBNavbarItem className="custom_navbar_item custom_profile cursor-pointer">
              {(localStorage.getItem('user_profile') !== undefined &&
                localStorage.getItem('user_profile') !== null) |
              (userProfile.length !== 0 && userProfile !== null) ? (
                <MDBNavbarLink
                  className="custom_navbar_links_text "
                  onClick={() => {
                    navigate('/profile');
                  }}
                >
                  My account
                </MDBNavbarLink>
              ) : (
                <MDBNavbarLink
                  className="custom_navbar_links_text "
                  onClick={() => {
                    setToggleCreateAccountModal(!toggleCreateAccountModal);
                  }}
                >
                  Create account
                </MDBNavbarLink>
              )}
            </MDBNavbarItem>
            <MDBNavbarItem className="custom_navbar_item cursor-pointer">
              {(localStorage.getItem('user_profile') !== undefined &&
                localStorage.getItem('user_profile') !== null) |
              (userProfile.length !== 0 && userProfile !== null) ? (
                <MDBNavbarLink
                  className="custom_navbar_links_text"
                  active
                  aria-current="page"
                  onClick={() => logOut()}
                >
                  Log out
                </MDBNavbarLink>
              ) : (
                <MDBNavbarLink
                  className="custom_navbar_links_text"
                  active
                  aria-current="page"
                  onClick={() => toggleShow2()}
                >
                  Log in
                </MDBNavbarLink>
              )}
            </MDBNavbarItem>
          </MDBNavbarNav>
        </MDBContainer>
      </MDBNavbar>

      {/* ------NEW LOGIN----- */}
      <MDBModal show={basicModal3} staticBackdrop setShow={setBasicModal3} tabIndex="-1">
        <MDBModalDialog className="detail_modal newloginmodal">
          <MDBModalContent>
            <MDBModalBody>
              <>
                <MDBBtn
                  className="btn-close float-right"
                  color="none"
                  onClick={closeModal3}
                ></MDBBtn>
                <MDBRow className="detail_login">
                  <MDBCol>
                    <MDBCard alignment="center" style={{ boxShadow: 'none' }}>
                      <MDBCardBody style={{ padding: '1rem 0 1rem 0' }}>
                        <MDBCardTitle>
                          <MDBTypography tag="h3" className="mb-0 fw-bold">
                            👨‍🍳 Hey! Sign in
                            {/* {(typeof mycards !== 'undefined' && mycards !== null)?'g':'ggg'} */}
                          </MDBTypography>
                        </MDBCardTitle>
                        <strong>
                          Log in or continue as guest.
                        </strong>{' '}
                        <MDBCardText>
                          {/*<a
                            className="signup_link"
                            //  href="/register"
                            onClick={() => {
                              toggleShow2();
                              setTimeout(() => {
                                setToggleCreateAccountModal(!toggleCreateAccountModal);
                              }, 400);
                            }}
                          >
                            &nbsp;Opret en konto
                          </a>*/}
                        </MDBCardText>
                        <MDBRow className="detail_login">
                          <MDBCol>
                            {/* <MDBInputGroup textBefore='Contact Number' className='mb-3'> */}
                            {/* <label for="username">Username</label> */}
                            <input
                              id="username"
                              className="form-control"
                              type="text"
                              placeholder="Enter email"
                              onChange={(e) => usernameChange(e.target.value)}
                              tabIndex={1}
                            />
                            {/* <label for="password">Password</label> */}
                            <input
                              id="password"
                              className="form-control"
                              type="password"
                              placeholder="Enter password"
                              onChange={(e) => passwordChange(e.target.value)}
                              tabIndex={2}
                            />
                            {/* </MDBInputGroup> */}
                            <MDBBtn
                              className="btn-lg detail_login_submit loginbtn"
                              // color='none'
                              onClick={() => logIn()}
                            >
                              Log in
                            </MDBBtn>
                            {/* <FacebookLogin
                              appId="1075876056704827"
                              autoLoad={false}
                              fields="name,email,picture"
                              callback={responseFacebook}
                              isMobile={false}
                              disableMobileRedirect={true}
                              render={(renderProps) => (
                                <MDBBtn
                                  onClick={renderProps.onClick}
                                  rounded
                                  className="text-dark sign_in_others fs-6 fbbtn"
                                  color="light"
                                >
                                  <MDBIcon fab icon="facebook-square fs-4 mr-4 fbicon" />{' '}
                                  <span>Log ind med Facebook</span>
                                </MDBBtn>
                              )}
                            /> */}
                            <MDBBtn
                              rounded
                              className="text-dark sign_in_others fs-6 guestbtn"
                              color="light"
                              onClick={() => {
                                logInAsGuest();
                                setBasicModal3(false);
                              }}
                            >
                              <MDBIcon fas icon="user fs-4 fbicon" />
                              <span>Order as guest</span>
                            </MDBBtn>
                            <MDBBtn
                              rounded
                              className="text-dark sign_in_others fs-6 emailbtn"
                              color="light"
                              onClick={() => {
                                setToggleCreateAccountModal(!toggleCreateAccountModal);
                                setBasicModal3(false);
                              }}
                            >
                              <MDBIcon fas icon="envelope fs-4 fbicon" />
                              <span>Create account via email</span>
                            </MDBBtn>
                            <MDBBtn
                              onClick={() => googleSingIn()}
                              rounded
                              className="text-dark sign_in_others fs-6 googlebtn"
                              color="light"
                            >
                              <MDBIcon fab icon="google fs-4 fbicon" />{' '}
                              <span>Log in with Google</span>
                            </MDBBtn>
                            {/*<MDBRow className="mt-4">
                              <MDBCol>
                                <MDBTypography tag="small">
                                  <p style={{ textAlign: 'center' }} className="eller">
                                    eller
                                  </p>
                                </MDBTypography>
                              </MDBCol>
                            </MDBRow>*/}
                            By creating a <strong>FREE</strong> HeyWaitr account, you'll not only
                            gain access to your receipts and order details but also enjoy faster
                            ordering in the future, as well as exclusive offers and loyalty
                            benefits.
                            <div className="forgotpassword forgotpasstxt ">
                              <span
                                className="cursor-pointer color-primary"
                                onClick={() => {
                                  setBasicModal3(false);
                                  setForgotPassword(true);
                                }}
                              >
                                Forgot password
                              </span>
                            </div>
                          </MDBCol>
                        </MDBRow>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
              </>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      {/* CREATE ACCOUNT */}
      <MDBModal
        show={toggleCreateAccountModal}
        staticBackdrop
        setShow={setToggleCreateAccountModal}
      >
        <MDBModalDialog centered className="newloginmodal signupmodal">
          <MDBModalContent>
            <MDBModalBody>
              <MDBBtn
                className="btn-close float-right"
                color="none"
                onClick={() => {
                  setToggleCreateAccountModal(false);
                }}
              ></MDBBtn>
              <MDBContainer className="signin_container" breakpoint="sm">
                <MDBRow className="detail_signin">
                  <MDBCol>
                    <MDBCard alignment="center">
                      <MDBCardBody>
                        <MDBCardTitle>
                          <MDBTypography tag="h3" className="mb-0 fw-bold">
                            Create your new heywaitr account
                          </MDBTypography>
                        </MDBCardTitle>
                        <MDBCardText>
                        Create your <strong>free</strong> account. You will be able to view your active orders and receipts all in one place, making it quick and easy to order.<br></br> Already have an account?{' '}
                          <b>
                            <a
                              className="signup_link cursor-pointer"
                              // href="/login"
                              onClick={() => {
                                setToggleCreateAccountModal(!toggleCreateAccountModal);
                                setTimeout(() => {
                                  toggleShow2();
                                }, 400);
                              }}
                            >
                              Log in
                            </a>
                          </b>
                        </MDBCardText>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
                <MDBRow className="detail_signin">
                  <MDBCol>
                    <MDBCard>
                      <MDBCardBody className="detail_signin_input_form">
                        <MDBTypography tag="span" className="mb-0 fw-bold">
                          General Information
                        </MDBTypography>
                        <MDBRow>
                          <MDBCol xs={12} md={6}>
                            <input
                              id="username"
                              className="form-control"
                              type="text"
                              placeholder="Your first name"
                              value={signupData['firstname']}
                              onChange={(e) => handleChangeSignup('firstname', e.target.value)}
                              tabIndex={1}
                            />
                          </MDBCol>
                          <MDBCol xs={12} md={6}>
                            <input
                              id="username"
                              className="form-control"
                              type="text"
                              placeholder="Your last name"
                              value={signupData['lastname']}
                              onChange={(e) => handleChangeSignup('lastname', e.target.value)}
                              tabIndex={2}
                            />
                          </MDBCol>
                        </MDBRow>

                        <input
                          id="email"
                          className="form-control"
                          type="text"
                          placeholder="Your email"
                          value={signupData['email1']}
                          onChange={(e) => handleChangeSignup('email1', e.target.value)}
                          tabIndex={3}
                        />
                        <input
                          id="email"
                          className="form-control"
                          type="text"
                          placeholder="Repeat email"
                          value={signupData['email2']}
                          onChange={(e) => handleChangeSignup('email2', e.target.value)}
                          tabIndex={4}
                        />
                        {signupData['email1'] && signupData['email2'] && isEmailNotMatch() ? (
                          <p className="fielderror">Email does not match</p>
                        ) : null}

                        <input
                          id="contact"
                          className="form-control"
                          type="text"
                          placeholder="Your mobile number"
                          value={signupData['mobilenumber']}
                          onChange={(e) => handleChangeSignup('mobilenumber', e.target.value)}
                          tabIndex={4}
                        />

                        <MDBTypography tag="span" className="mb-0 fw-bold">
                          Password
                        </MDBTypography>
                        <input
                          id="password"
                          className="form-control"
                          type="password"
                          placeholder="Create a password"
                          value={signupData['password1']}
                          onChange={(e) => handleChangeSignup('password1', e.target.value)}
                        />
                        <input
                          id="password"
                          className="form-control"
                          type="password"
                          placeholder="Repeat the password"
                          value={signupData['password2']}
                          onChange={(e) => handleChangeSignup('password2', e.target.value)}
                          tabIndex={5}
                        />
                        {signupData['password1'] &&
                        signupData['password2'] &&
                        isPasswordNotMatch() ? (
                          <p className="fielderror">Password does not match</p>
                        ) : null}

                        <div className="flex">
                          <input
                            id="agree_to_terms"
                            className="_checkmark"
                            type="radio"
                            onChange={(e) => agreeTermsChange(e.target.value)}
                          />
                          <label for="agree_to_terms" className="agree_to_terms">
                              To create an account, you must accept our{' '}
                            <b>
                              <a
                                href="https://www.heywaitr.com/heywaitr-app-global-terms-and-conditions/"
                                target="_blank"
                                style={{ color: '#94C11E' }}
                              >
                                Terms
                              </a>
                            </b>
                          </label>
                        </div>
                        <MDBBtn
                          className="btn-lg detail_signin_submit mt-4"
                          onClick={() => signUpNew()}
                        >
                          Create Account
                        </MDBBtn>
                        <p style={{ textAlign: 'center' }}></p>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      {/* -----RESET PASSWORD---- */}
      <MDBModal show={forgotPassword} staticBackdrop setShow={setForgotPassword} tabIndex="-1">
        <MDBModalDialog className="detail_modal newloginmodal">
          <MDBModalContent>
            <MDBModalBody>
              <>
                <MDBBtn
                  className="btn-close float-right"
                  color="none"
                  onClick={() => setForgotPassword(false)}
                ></MDBBtn>
                <MDBRow className="detail_login">
                  <MDBCol>
                    <MDBCard alignment="center">
                      <MDBCardBody>
                        <MDBCardTitle>
                          <MDBTypography tag="h3" className="mb-0 fw-bold">
                            Reset password
                          </MDBTypography>
                        </MDBCardTitle>

                        <MDBRow className="detail_login">
                          <MDBCol>
                            <form onSubmit={submitReset}>
                              <input
                                id="username"
                                className="form-control"
                                type="email"
                                placeholder="Enter email"
                                onChange={(e) => setForgotPasswordEmail(e.target.value)}
                                value={forgotPasswordEmail}
                                required
                              />

                              <MDBBtn className="btn-lg detail_login_submit loginbtn">
                                Reset password
                              </MDBBtn>
                            </form>
                          </MDBCol>
                        </MDBRow>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
              </>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}
